@import 'view/styles/_variables.scss';

.dashboard-tile-menu {
	cursor: default;

	.graph-table-menu {
		.menu-item-not-selected {
			color: $border-grey-dark-2 !important;
			:hover {
				color: $org-grey !important;
			}
		}

		.menu-item-selected {
			cursor: default;
			color: $org-grey !important;
			:hover {
				color: $org-grey !important;
			}
		}
	}
}
