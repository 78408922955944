@import 'view/styles/_variables.scss';

.search-bar {
	.p-inputtext {
		::placeholder {
			color: $org-blue !important;
		}

		&:not(.p-disabled).p-focus {
			box-shadow: none;
		}

		color: #39526e !important;
		font-weight: bold !important;
		height: 33px;
		width: 140px;
		background-color: #ededed;
		border: none !important;
		border-radius: 0;
		margin-bottom: 0rem;
		width: max(21%, 160px);
	}
}
