@import 'view/styles/_variables.scss';
@import 'view/styles/_variables.scss';

.font-size-small {
	font-size: $font-size-small;
}

.font-size-normal {
	font-size: $font-size-normal;
}

.font-size-big {
	font-size: $font-size-big;
}

.font-size-2em {
	font-size: 2em;
}

.font-size-3-8em {
	font-size: 3.8em;
}

.line-height-normal {
	line-height: $font-size-normal;
}

.text-bold {
	font-weight: bold;
}

.text-bolder {
	font-weight: bolder;
}

.text-capitalize {
	text-transform: capitalize;
}

.text-lowercase {
	text-transform: lowercase;
}

.text-center {
	text-align: center;
}

.text-left {
	text-align: left;
}

.text-right {
	text-align: right;
}

.white-space-nowrap {
	white-space: nowrap;
}

.white-space-pre-line {
	white-space: pre-line;
}

.white-space-pre {
	white-space: pre;
}

.white-space-pre-wrap {
	white-space: pre-wrap;
}

.link {
	color: $link-blue;
	text-decoration: underline;

	&:hover {
		color: darken($link-blue, 20%);
	}
}

.text-direction-rtl {
	direction: rtl;
}

.word-break {
	word-wrap: normal;
}

.word-break-all {
	word-wrap: break-word;
	overflow-wrap: break-word;
	white-space: pre-wrap;
}

.disabled-text {
	color: $chart-gray-light;
	font-style: italic;
}

.font-italic {
	font-style: italic;
}
