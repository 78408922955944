@import "view/styles/_variables.scss";

$LOOP_MIN: 1;
$LOOP_MAX: 100;
$LOOP_ONE_THIRD: $LOOP_MAX / 3;
$LOOP_TWO_THIRDS: $LOOP_MAX * 2 / 3;

// This generates $LOOP_MAX classes. Not efficient but this, along with width, centers text in visible bar
@for $i from $LOOP_MIN through $LOOP_MAX {
    .p-progressbar.label-width-#{$i} {
        &.green{
            div{
                background: linear-gradient(to right, $progressbar-green-light, $progressbar-green-dark);
            }
     

        }
        &.yellow{
            div{
                background: linear-gradient(to right, $progressbar-orange-light, $progressbar-orange-dark);
            }
          
        }
        &.red{
            div{
                background: linear-gradient(to right, $progressbar-red-light, $progressbar-red-dark);
            }
  
        }
        .p-progressbar-label,
        .p-progressbar-value {
            width: round(percentage($i/100)) !important;

       
        }
    }
}

// --- Special cases for progress bar values ---

.p-progressbar.label-width-50 {
    .p-progressbar-label {
        // &::after {
            content: "< 50%" !important;
        // }
    }
}

// --- Special cases end ---

$bar-border-radius: 20px;

.p-progressbar.progress-bar {
    background-color: transparent;
    border: 0.5px solid $border-grey;
    border-radius: $bar-border-radius;

    .p-progressbar-label {
        color: white;
        text-shadow:
            0px 1px 3px rgba(0, 0, 0, 0.4),
            0px 8px 13px rgba(0, 0, 0, 0.1),
            0px 18px 23px rgba(0, 0, 0, 0.1);
        border-radius: $bar-border-radius;
    }

    .p-progressbar-value {
        border-radius: $bar-border-radius;
    }
}