@import 'view/styles/_variables.scss';

$line-width-thin: 1px;
$line-width-normal: 2px;
$line-width-thick: 4px;
$line-width-thicker: 6px;

/// @direction-postfix A direction appended to 'border-'. So the supported values are 'top', 'right', 'bottom', 'left'.
@mixin borderGeneral($direction-postfix: bottom,
	$line-width: $line-width-normal,
	$line-style: solid,
	$color: $border-grey) {
	border-#{$direction-postfix}: $line-width $line-style $color;
}

.border-bottom-grey {
	@include borderGeneral($line-width: $line-width-normal);
}

.border-bottom-grey-thin {
	@include borderGeneral($line-width: $line-width-thin);
}

.border-top-grey-thin {
	@include borderGeneral($line-width: $line-width-thin, $direction-postfix: top);
}

.border-bottom-grey-thick {
	@include borderGeneral($line-width: $line-width-thick);
}

.border-radius-10 {
	border-radius: 10px;
}

.border-top-grey {
	@include borderGeneral(top, $line-width-normal);
}

.border-right-grey {
	@include borderGeneral(right, $line-width-normal);
}

.border-right-shaded {
	border-right: 0 none;
	box-shadow: 5px 0px 5px rgba(0, 0, 0, 0.1);
	z-index: 10;
}

.border-left-org-primary {
	border-left: solid $line-width-thicker;
	border-color: $org-primary;
}

.border-dashed {
	border: dashed 2px $border-grey-dark-2;
}