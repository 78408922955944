@import 'view/styles/borders.scss';

.dashboard-tile {
	cursor: grab;
	:active {
		cursor: grabbing;
	}
	.card-container-padded {
		height: 100%;
	}
	.card-container {
		overflow: hidden;
	}

	.menu {
		bottom: -20%;
		right: 0;
		background-color: white;
		height: 20%;
		border-radius: 10px 10px 5px 5px;
		transition: all 0.3s ease;

		&.showMenu {
			box-shadow: 0 0px 8px 0 $border-grey-dark-2;
			bottom: 0;
		}

		&.item {
			padding: 5px;
		}

		&.items {
			padding: 30px;
			width: 100%;
		}
	}

	.link-style-unset {
		color: unset;
		text-decoration: unset;
	}

	.link-style-unset:hover {
		color: unset;
		text-decoration: unset;
	}

}

.dashboard-chart-pie {
	.dashboard-table {
		display: flex;
	}
}

.dashboard-table {
	border-collapse: collapse;
	&.align-center tr td {
		text-align: center;

		&:first-child {
			@include borderGeneral(right, $line-width-normal);
		}
	}
}

$chart-width: 175px; // Make sure the tooltip isn't cut off when too long. Known issue since 2014: https://github.com/chartjs/Chart.js/issues/622

.dashboard-chart-pie {
	display: flex;

	.p-chart {
		height: $chart-width;
		width: $chart-width;
	}
}

.dashboard-tile-info {
	max-width: 150px;
}