@import 'view/styles/_variables.scss';

.height-width-auto-important {
	width: auto !important;
	outline: none;
}

$line-height-default: 1.2em;
$line-count-default: 2;

/* mixin for multiline */
@mixin multiLineEllipsis($lineHeight: $line-height-default, $lineCount: $line-count-default, $bgColor: white) {
	overflow: hidden;
	position: relative;
	line-height: $lineHeight;
	max-height: $lineHeight * $lineCount;
	text-align: justify;
	margin-right: -1em;
	padding-right: 1em;

	&::before {
		content: '...';
		position: absolute;
		right: 0;
		bottom: 0;
		background-color: white;
	}

	&::after {
		content: '';
		position: absolute;
		right: 0;
		width: 1em;
		height: 1em;
		margin-top: 0.2em;
		background: white;
	}
}

.multiline-text-ellipsis {
	@include multiLineEllipsis();
}

.units-selected-container {
	width: 100%;
}

.dialog-box-auto-height {
	height: 55vh !important;
	max-height: 600px;
}

.dialogConatiner {
	width: 1100px;
}

.search-field {
	flex: 1;
	div {
		justify-content: end;
	}
}
