@import 'view/styles/_variables.scss';

// Relevant link with explanation: https://stackoverflow.com/a/6245879
$x-offset-1: 2px;
$y-offset-1: 2px;
$blur-effect-1: 3px;
$spread-effect-1: -#{$blur-effect-1};

$y-offset-2: -3px;

@mixin biContentRow($size) {
	padding: 0px 20px;
	border-radius: 20px;
	display: grid;
	grid-template-columns: $size;
	align-items: center;
	outline: none;
}

.bi-label-content-item {
	@include biContentRow(55% auto);
}

.bi-label-content-item-lg {
	@include biContentRow(40% auto);
}

.bi-label-content-item-lg-2 {
	@include biContentRow(35% auto);
}

.bi-label-content-item-lg-3 {
	@include biContentRow(300px auto);
}

.bi-label-content-item-box-shadow {
	// A shadow per comma. First shadow is moved to the bottom right. Second shadow is moved to the bottom left.
	box-shadow: $x-offset-1 $y-offset-1 $blur-effect-1 0 $border-grey-dark,
		-#{$x-offset-1} #{$y-offset-1} $blur-effect-1 0 $border-grey-dark;
	padding: 10px 20px !important;
}

@mixin biContentContainer($size) {
	display: grid;
	grid-template-columns: $size;
	align-items: center;

	.pi-times-circle {
		cursor: pointer;
		font-size: 21px;
		color: $org-grey;

		&:hover {
			color: $border-grey-dark-2;
		}
	}

	> label {
		margin-bottom: 0px;
		font-weight: bold;
	}
}

.content-container {
	@include biContentContainer(48% 48% auto);

	.p-component {
		margin-right: 0px !important;
	}
}

.content-container-large {
	@include biContentContainer(200px 120px 80px 80px 80px 100px 90px 45px);
	column-gap: 10px;
}

.content-container-groups {
	@include biContentContainer(295px 200px 5%);
	column-gap: 66px 30px;
}

.content-container-unit-models {
	@include biContentContainer(150px 1fr 1fr 1fr 110px 1fr 70px);
	column-gap: 10px;
}

.content-container-unit-manufacturers {
	@include biContentContainer(1fr 70px);
	column-gap: 10px;
}

.content-container-container {
	@include biContentContainer(140px 140px 100px);
	column-gap: 50px;
}

.content-container-container-boxnames {
	@include biContentContainer(208px 163px auto);
	column-gap: 50px;
}

$debtors-size: 3.5fr 2fr 2fr 1.2fr 1fr 1fr;

@mixin debtor-common {
	column-gap: 10px;

	.debtor-checkbox-center {
		justify-self: center;
	}
}

.content-container-debtors {
	@include biContentContainer($debtors-size);
	@include debtor-common();
	margin-right: 20px;
	margin-left: 20px;
	justify-items: start;
}

.debtor-row {
	@include biContentRow($debtors-size);
	@include debtor-common();
}

$decommission-size: 1fr 1fr 1fr 1fr;

.content-container-decommission {
	@include biContentContainer($decommission-size);
	@include debtor-common();
	margin-right: 20px;
	margin-left: 20px;
	justify-items: start;
}

.decommission-row {
	@include biContentRow($decommission-size);
	@include debtor-common();
}
