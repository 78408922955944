@import "view/styles/_variables.scss";

.pressure-graph-container {
    overflow: auto;
    canvas {
        width: 100% !important;
    }
}

.pressure-graph-table-wrapper-container {
    height: 76vh;

    .table-responsive {
        overflow-x: hidden !important;
    }
}