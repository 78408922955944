@import 'view/styles/_variables.scss';

@mixin input-box-generic($width) {
	width: $width;

	border-radius: 0px !important;
	border-top: none !important;
	border-left: none !important;
	border-right: none !important;
	border-bottom: 1px solid $border-grey-dark;

	padding: 0px 0px 2px 0px !important;

	outline: 0px !important;
	margin-left: 0px !important;
}


.input-box-bramidan {
	@include input-box-generic(75%);
}

.input-box-bramidan-1 {
	@include input-box-generic(50%);
}

.input-box-width-100-bramidan {
	@include input-box-generic(100%);
}

.card-width-1300-and-margin-spacing {
	&.card-width {
		max-width: (1300px + $spacing-margin-40-px);
	}
}