@import 'view/styles/_variables.scss';

.operator-selected {
    box-shadow:inset 0px 0px 0px 1px $org-primary;

}

.operator-root {
    width: 20px;
    height: 20px;
}