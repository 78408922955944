@import 'view/styles/_variables.scss';

.scrollpanel-content-flex {
	.p-scrollpanel-content {
		display: flex;
		flex-wrap: wrap;
		height: 100%;
	}
}

@mixin bi-scroll-panel-solid($bar-color: $org-primary-light, $bar-color-hover: $org-primary-dark) {
	.p-scrollpanel-bar {
		background-color: $bar-color;
		opacity: 1;
		transition: background-color 0.3s;
	}

	.p-scrollpanel-bar:hover {
		background-color: $bar-color-hover;
	}
}

.bi-scroll-panel-solid-bramidan {
	@include bi-scroll-panel-solid();
}