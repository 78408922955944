$size-bar-label: 2em;

.p-progressbar.kpi-fill-level-progress-bar {
	height: $size-bar-label + 0.5em;

	.p-progressbar-label {
		font-size: $size-bar-label;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
