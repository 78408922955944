@import '../details.scss';

.document-btn-content {
	width: ($card-width-big - $spacing-margin-40-px - 32 * 2);
	height: 30px;
	justify-content: flex-start;
	display: flex;
	align-items: center;
}

.document-btn-container {
	margin-top: 10px;
	display: inline-block;
}

.document-btn-dialog-grid {
	display: grid;
	column-gap: 20px;
	row-gap: 5px;
	justify-content: space-between;
	grid-template-columns: repeat(1, auto);
}
