@import 'view/styles/_variables.scss';

.card-width-700-and-margin-spacing {
	&.card-width {
		width: (700px + $spacing-margin-40-px);
	}
}

.card-width-800-and-margin-spacing {
	&.card-width {
		width: (800px + $spacing-margin-40-px);
	}
}

.card-max-width-and-margin-spacing {
	max-width: 500px + $spacing-margin-40-px;

	&.info {
		min-width: 524px;
		overflow: hidden;
	}

	&.map {
		max-width: (700px + $spacing-margin-40-px);
		margin-left: 40px;
	}
}

.card-padding-0 {
	&.card-container-padded {
		padding: 0;
	}
}

.card-width-auto {
	&.card-width {
		width: auto;
	}
}

.card-width-fit-content {
	&.card-width {
		width: auto;
	}
}

.limit-height {
	min-height: 250px;
	max-height: 500px;
}

.flexDirectionRow {
	flex-direction: row;
	display: flex;
}

.history-container {
	.static-map {
		object-fit: cover;
	}
}

.history {
	.p-tabview {
		.p-tabview-panels {
			padding: 0;
			background-color: transparent;
		}

		.p-tabview-nav {
			&.p-reset {
				margin-bottom: 0;
			}
		}
	}
}