@import "view/styles/_variables.scss";
@import "view/styles/borders.scss";

@mixin textfieldGeneral($background-color: $table-row-grey,
    $horizontal-padding: 10px,
    $vertical-padding: 5px,
    $border-radius: 5px,
    $min-width: 200px,
) {
    background-color: $background-color;
    padding-left: $horizontal-padding;
    padding-right: $horizontal-padding;
    padding-top: $vertical-padding;
    padding-bottom: $vertical-padding;
    border-radius: $border-radius;
    min-width: $min-width;
}

.specific-notification {
    background-color: #fff;

    #not-save-cancel {
        justify-content: flex-end;
    }

    .editDefinitionName {
        @include textfieldGeneral()
    }

    .wider-inputfield {
        min-width: 230px !important;
    }

    .p-tabview {
        .p-tabview-panels {
            @extend .border-top-grey-thin;
        }
    }
}