$hide-at-width: 750px;
.notification-card-temp-subscription-icon {
	position: relative;
	height: 25px;
	width: 140px;
	text-align: center;

	@media (max-width: $hide-at-width) {
		svg {
			transform: none;
			position: relative;
			height: 42px;
		}
	}

	@media (min-width: $hide-at-width) {
		svg {
			position: absolute;
			right: 0px;
			bottom: 0px;
			transform: translateY(50%);
		}
	}
}

.re-activate-icon {
	width: 75px;
}
