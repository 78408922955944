@import "view/styles/_variables.scss";
@import "view/styles/_gradients.scss";

.multiselect-button {
	&.p-button {
		background-color: transparent;
        border: 1px solid black; 
        margin-right: 15px;
        // margin-top: 30px;

		&:enabled {
			&:hover {
				background-color: transparent;
                background-color: black;

				border-color: transparent;
				&:not(.active) .p-button-text {
                    color: white;
				}
            }
        }
        
        .p-button-text {
            padding: .25em 1.25em;
        }
	}

	&.active {
		.p-button-text {
			color: white;
        }
        background-color: black;
	}
}