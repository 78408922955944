@import "../../../styles/variables";


.bi-input-number {
    max-width: fit-content;

    .p-inputnumber-button-up, .p-inputnumber-button-down, .p-button:enabled:hover {
        background-color: $org-primary;
        border-color: $org-primary;
    }
}