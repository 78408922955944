.map-height {
    max-height: 711px;
}

.map-headline {
    position: absolute;
    padding-top: 32px;
    padding-left: 32px;
    color: black;
    font-weight: bold;
}