@import "view/styles/_variables.scss";

.bi-checkbox {
    >div {
        &.p-checkbox-box {
            &.p-component {
                &.p-highlight {
                    border-color: $org-primary-light;
                    background-color: $org-primary-light;

                    &:not(.p-disabled):hover {
                        border-color: $org-primary-dark;
                        background-color: $org-primary-dark;
                    }
                }
            }
        }
    }
}


