.notification-subscription-list {
	.notification-subscription-grid {
		text-align: center;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;

		.edit-btn-container {
			justify-self: flex-end;
			display: inline-block;
		}

		.justify-self-start {
			justify-self: flex-start;
		}
	}
}
