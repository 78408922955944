@import 'view/styles/_variables.scss';

.advanced-notification-container {
	min-height: 450px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	.add-new-advanced-notification {
		margin-top: 10px;
	}

	.notification-settings-container {
		display: flex;
		row-gap: 15px;
		flex-direction: column;
	}

	.notification-setting-container {
		display: flex;
		align-items: center;

		.notification-delete-btn {
			margin-left: -7px !important;
			margin-right: 10px !important;
		}
	}
}

#advanced-notification-dialog-container {
	width: 54%;
	min-width: 800px;
	max-width: 85vw;
}
