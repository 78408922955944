@import "view/styles/_variables.scss";

body {
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(to bottom, white 0px, white 440px, $metal-grey 440px, $metal-grey 100%);
    min-height: 100vh;
    font-size: 14px;
}

.text-primary {
    color: $org-primary !important;
}

.bg-primary {
    background-color: $org-primary !important;
}

.p-component {
    font-family: 'Roboto', sans-serif !important;
}