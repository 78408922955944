@import 'view/styles/_variables.scss';

.tree-select-dropdown {
	&.p-multiselect {
		&.p-component {
			background-color: #ededed;
			min-width: 140px;
			width: unset;
			max-width: 300px;

			&.org-background {
				background-color: $org-primary !important;
			}

			.org-background {
				background-color: $org-primary !important;
				color: white !important;

				label {
					color: white !important;
					min-width: 140px;
					width: unset !important;
				}

				&.p-multiselect-label-container {
					min-width: 140px;
					width: unset !important;
				}
			}
		}
	}

	.tree-select-dropdown-content {
		position: absolute;
		background-color: white;
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		padding: 12px 16px;
		top: 33px;
		left: 0px;
		transition: transform 0.3s, opacity 0.3s, -webkit-transform 0.3s;
		z-index: 1001;

		.react-checkbox-tree {
			label:hover {
				background: none;
			}
		}

		.rct-text {
			label {
				display: flex;
			}

			.rct-title {
				white-space: nowrap;
			}
		}

		.rct-node-icon {
			display: none;
		}

		.rct-node-leaf {
			//padding: 8px 0;
		}
	}

	.is-open {
		display: block;
		opacity: 1 !important;
		transform: translateY(0);
	}

	.is-closed {
		display: none;
		opacity: 0 !important;
		transform: translateY(5%);
	}
}