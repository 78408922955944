@import "view/styles/_variables.scss";

$IconBigSize: 48px;

.margin-left-10px {
    margin-left: 10px;
}

.icon-size {
    font-size: $IconBigSize;
}

.status-general {
    width: 22px;
    height: 22px;
    border-radius: 50%;
}

.status-online {
    @extend .status-general;
    background: linear-gradient(to right, $progressbar-green-light, $progressbar-green-dark);
}

.status-warning {
    @extend .status-general;
    background: linear-gradient(to right, $progressbar-orange-light, $progressbar-orange-dark);
}

.status-offline {
    @extend .status-general;
    background: linear-gradient(to right, $progressbar-red-light, $progressbar-red-dark);
}


