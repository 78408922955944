.devextreme-react-grid-remove-margin-bottom {
    table {
        &.dx-g-bs4-table {
            margin-bottom: 0 !important;
        }
    }
}

.width-inherit {
    width: inherit;
}

.width-180px {
    width: 180px;
}

.width-230-px {
    width: 230px;
}

.width-250-px {
    width: 250px;
}

.width-auto {
    width: auto;
}

.width-50-percent {
    width: 50%;
}

.width-80-percent {
    width: 80%;
}

.width-100-percent {
    width: 100%;
}

.width-fit-content {
    width: fit-content;
}