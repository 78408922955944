@import "view/styles/_variables.scss";

$border-width-default-cell: 1px;
$border-width-fixed-cell: $border-width-default-cell+0px;
$border-custom-default: $border-width-default-cell solid $border-grey !important;
$border-fixed-custom-default: $border-width-fixed-cell solid $border-grey-dark-2 !important;

@mixin bi-table-border-fixed-custom($border-right: $border-fixed-custom-default, $border-left: $border-fixed-custom-default) {
	&.border-left {
		border-left: $border-left;
	}

	&.border-right {
		border-right: $border-right;
	}
}

@mixin bi-table-border-default-cell-custom($border-right: $border-custom-default, $border-left: $border-custom-default) {
	border-right: $border-right;
}

.table {
	margin-bottom: 0;
}

.table-header {
	background-color: $org-grey;
	color: white;

	>tr {
		>th {
			padding: 0.75rem 0.375rem 0.75rem 0.375rem;
			background-color: $org-grey !important;

			.dx-g-bs4-sort-indicator-invisible {
				display: none
			}

			&:not(.border-right):not(.border-left) {
				// @include bi-table-border-default-cell-custom();
			}
		}
	}
}

.bi-table-striped {
	>tbody {
		>tr {
			td {
				padding: 7px;
				vertical-align: middle;
				@include bi-table-border-fixed-custom();
				@include bi-table-border-default-cell-custom();
				white-space: nowrap !important;

				.td-icon{
					height: $font-size-big;
				}
			}
		}
	}
}

.bi-table-header {
	.fa-info-circle {
		margin-left: 8px;
		margin-bottom: 3px;
	}
}

.textAlignCenter {
	text-align: center
}

.color-background {
	background-color: $table-row-grey !important;

	td {
		background-color: $table-row-grey !important;
	}
}

.dx-g-bs4-fixed-cell {
	background-color: white !important;
}