@import "view/styles/_variables.scss";

.dropdown {
    position: relative;
    display: inline-block;
}

.dropdown-content {
    // display: none;
    position: absolute;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
}

.dropdown-content a {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
    cursor: pointer;
}

.dropdown:hover .dropdown-content {
    display: block;
}

button {
    &.download-excel {
        .spinner-container-flex {
            &.spinner-container-half-size {
                margin-top: 0;
                padding-right: 14px;

                div {
                    height: 30px;
                    width: 30px;
                }
            }
        }
    }
}
