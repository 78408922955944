
.comparison-operators {
    display: flex;
    flex-direction: column;

    input {
        display: none;
        visibility: hidden;    
    }

    label {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        height: 20px;
        margin-bottom: 0px;
        padding: 15px;

        font-weight: 700;
        font-size: 20px;
        cursor: pointer;
    }
}