@import "view/styles/_variables.scss";

@mixin p-accordion-header-color-theme {
    color: $org-blue;
}

body .p-accordion {
    .p-accordion-header:not(.p-disabled).p-highlight a {
        background-color: $org-primary;
        border: 1px solid $org-primary;

        :focus {
            box-shadow: unset !important;
        }
    }

    outline: none !important
}

body .p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover a {
    border: 1px solid $org-primary;
    background-color: $org-primary;
}

body .p-accordion .p-accordion-header {
    a {
        @include p-accordion-header-color-theme();
        background-color: #fff;
        box-shadow: unset !important;
        border-radius: 0;
    }

    // Force another color scheme when triggering a hover event on a accordion item from a cluster marker.
    &:not(.p-highlight):not(.p-disabled):hover a {
        @include p-accordion-header-color-theme();
    }
}