.padding-2 {
    padding: 2em;
}

.padding-bottom-15px {
    padding-bottom: 15px;
}
.padding-bottom-2 {
    padding-bottom: 2em;
}

.padding-left-10px {
    padding-left: 10px;
}

.padding-left-20px {
    padding-left: 20px;
}

.padding-left-32px {
    padding-left: 32px;
}

.padding-left-50px {
    padding-left: 50px;
}

.padding-right-1em {
    padding-right: 1em;
}

.padding-right-2 {
    padding-right: 2em;
}

.padding-right-3 {
    padding-right: 3em;
}

.padding-right-10px {
    padding-right: 10px;
}

.padding-right-20px {
    padding-right: 20px;
}

.padding-right-32px {
    padding-right: 32px;
}

.padding-right-50px {
    padding-right: 50px;
}

.padding-top-15px {
    padding-top: 15px;
}

.padding-top-50px {
    padding-top: 50px;
}

.padding-vertical-0 {
    padding-top: 0;
    padding-bottom: 0;
}

.padding-vertical-0-5 {
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.padding-vertical-0-9 {
    padding-top: 0.9em;
    padding-bottom: 0.9em;
}

.padding-vertical-10px {
    padding-top: 10px;
    padding-bottom: 10px;
}

.padding-horizontal-32px {
    padding-left: 32px;
    padding-right: 32px;
}
