@import "view/styles/_variables.scss";

.p-component {
    &.p-button {
        &.p-button-margin-top {
            margin-top: 20px;
        }

        &.p-button-custom {
            border-radius: 10px;
            margin: 0 10px;
            border: none;

            &:enabled:hover {
                border: none;
            }

            .p-button-text {
                font-weight: bold;
            }
        }

        &.bi-button-icon-only {
            background-color: transparent;
            font-size: 1.9em;
            padding: 0;
            color: $org-grey;
            margin: 0;
            max-width: 40px;
            &.org-color {
                color: $org-primary;
            }

            .p-button-text {
                padding: 0;
            }
        }

        &.bi-button-img-only {
            background-color: transparent;
            padding: 0;
            color: $org-grey;
            margin: 0;
            max-width: 40px;

            span {
               display: none;
            }
            &:hover{
                opacity: 0.3;
            }
        }
        &.p-button-icon-only:hover {
            background-color: transparent;
            color: $border-grey-dark-2;
            &.org-color {
                color: $org-primary-light;
            }

        }
    }
}

.p-button-custom-icon {
    &.p-button {
        display: flex;
        flex-direction: row;
        align-items: center;

        &.p-button-text-only {
            .p-button-text {
                padding-right: 2.5em;
            }
        }

        svg {
            margin-right: 1em; // This assumes that the icon is positioned to the right.
        }
    }
}