@import 'view/styles/_variables.scss';
@import 'view/styles/borders.scss';

$hide-at-width: 1150px;

nav {
	@extend .border-bottom-grey;
	width: 100%;
	background-color: white;
	padding-left: 30px;
	padding-right: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	.first-section {
		display: flex;
	}

	.nav-links {
		margin-bottom: 0 !important;
		vertical-align: middle;
		list-style: none;
		display: flex;
		padding-left: 0;

		.nav-item {
			font-size: 13px;
			text-transform: uppercase;

			&:hover {
				background-color: white;
			}

			a,
			.nav-link {
				&:hover {
					color: $navbar-accent;
				}

				font-weight: bold;
				display: inline-block;
				padding: 10px 15px;
				text-decoration: none;
				color: $org-grey;
				border-top: 2px solid transparent; // should prevent wonkyness when going from main page -> any page from menu
				border-bottom: 2px solid transparent; // should prevent wonkyness when going from main page -> any page from menu
			}

			&.user-name {
				align-self: center;
				color: $org-grey;
				opacity: 0.5;
				padding: 10px 15px 10px 0px;
				text-overflow: ellipsis;
				white-space: nowrap;
				overflow: hidden;

				@media (max-width: $hide-at-width) {
					display: none;
				}

				@media (max-width: 1300px) {
					max-width: 135px;
				}
			}

			&.brand-link {
				@media (max-width: $hide-at-width) {
					display: none;
				}
			}

			&.header-divider {
				border-left: 0.5px solid $org-grey;
				border-right: 0.5px solid $org-grey;
				opacity: 0.3;
				align-self: center;
				height: 20px;
			}

			.current-page {
				border-bottom: 2px solid $navbar-accent;
			}

			.nav-icon-container {
				margin-right: 10px;
				.icon {
					position: absolute;
					font-size: medium;
					color: black;
					cursor: pointer;
				}

				.not-circle {
					height: 8px;
					width: 8px;
					margin: -8px;
					border-radius: 50%;
					color: $org-primary;
					background-color: $org-primary;
					position: relative;
					top: 5px;
					left: 18px;
				}
			}

			.p-overlaypanel {
				margin-top: 18px;
				margin-left: 150px;

				&:after,
				&:before {
					left: unset;
					right: 148px;
				}
			}
			.p-overlaypanel-content {
				width: 1000px;
				max-height: 700px;
				overflow-y: auto;
			}
		}
	}

	.logo {
		display: inline-block;

		img {
			align-items: center;
			vertical-align: middle;
			display: flex;
			margin-right: 20px;

			&.brand-logo {
				&::before {
					content: '';
				}

				background: $brand-logo-path no-repeat;
				background-size: contain;
				width: $brand-logo-width;
				padding: 20px 10px;
				vertical-align: middle;
			}
		}
	}
}
