@import 'view/styles/_variables.scss';

.p-multiselect.p-component {
	height: 33px;
	display: flex;
	width: 140px;
	border: none;
	margin-right: 10px;
	background-color: $metal-grey;


	&.not-empty {
		background-color: $org-primary;
		.p-multiselect-label-container {
			background-color: $org-primary;
			max-width: 140px;
			height: 33px;
			margin-bottom: 0rem;

			.p-multiselect-label {
				color: #ffffff !important;
			}
		}

		.p-multiselect-trigger {
			background-color: $org-primary !important;
			color: #ffffff;
		}
	}

	.p-multiselect-panel {
		.p-input-overlay {
			.p-multiselect-items-wrapper {
				max-height: 390px;
			}
		}
	}

	.p-checkbox {
		.p-checkbox-box {
			border-color: black;

			.p-checkbox-icon {
				background-color: $org-primary;
				border-color: $org-primary;
			}
		}
	}

	.p-multiselect-label-container {
		width: 140px;
		color: #ffffff;
		height: 33px;
		margin-bottom: 0rem;

		label {
			// Fixes for words with spaces not displaying ellipsis.
			width: 140px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			color: $org-blue !important;
			font-weight: bold !important;
			height: 27px;
		}
	}

	.p-multiselect-trigger {
		background-color: $metal-grey !important;
	}

	.p-inputtext:enabled:focus {
		outline: 0 none !important;
		outline-offset: 0 !important;
		-webkit-box-shadow: 0 !important;
		box-shadow: 0 !important;
	}

	.p-float-label input:focus ~ label,
	.p-float-label input.p-filled ~ label,
	.p-float-label .p-inputwrapper-focus ~ label,
	.p-float-label .p-inputwrapper-filled ~ label {
		top: -0.85em !important;
		font-size: 12px;
	}

	.p-multiselect-item {
		padding: 0.429em 1.857em 0.429em 0.857em !important;

		&.p-highlight {
			color: #333333 !important;
			background-color: #ffffff !important;
		}

		&:focus {
			box-shadow: none !important;
		}

		label {
			margin-bottom: 1px;
		}
	}
}
