@import 'view/styles/_variables.scss';

#service-video {
	.service-video-grid {
		text-align: center;
		display: grid;
		grid-template-columns: 1fr 1fr 2fr 1fr;
		column-gap: 10px;

		.min-height-50px {
			min-height: 50px;
		}
	}
}

// .grey-text {
// 	color: $cylinder-grey;
// }

.service-video-dialog {
	width: 60%;
}
