@import 'view/styles/_variables.scss';
@import 'view/styles/borders.scss';

.notifications-list {
	.accordion {
		.p-component.p-button.bi-button-icon-only {
			margin-left: 5px;
			height: 40px;
			width: 40px;
			border: solid 1px $org-primary;
			border-radius: 50%;
			color: $org-primary;
		}
		.p-component.p-button.p-button-custom:enabled:hover {
			color: $org-primary-dark;
			border: solid 2px $org-primary-dark;
		}

		.content {
			margin-left: -$line-width-thicker;
			align-items: flex-start;
		}
	}

	.single-line-ellipsis {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	.accordion-grid {
		display: grid;
		grid-template-columns: 10% 24% 20% 10% 12% 10% auto;
		column-gap: 15px;
		align-items: center;
	}

	.mini-grid {
		display: grid;
		grid-template-columns: 33% 66%;
		column-gap: 15px;
		row-gap: 20px;
		align-items: flex-start;
	}

	.grid-controller-notifications {
		grid-template-rows: auto auto auto auto;
	}

	.mini-card {
		width: fit-content;
		height: fit-content;
		padding: 5px;
		margin-right: 10px;
		margin-top: 10px;
	}

	.warning-icon {
		margin-left: 5px;
		height: 40px;
		width: 40px;
		vertical-align: bottom;
	}
}
