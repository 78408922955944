@import "view/styles/_variables.scss";
@import "view/styles/shadows.scss";

$border-radius-5px: 5px;
$border-radius-10px: 10px;
$border-radius-18px: 18px;
$border-radius-20px: 20px;

.bi-button-big-border-spacing {
    padding: 10px 20px;
}

.bi-button-slim-border-spacing {
    padding: 5px 30px;
}

.bi-button-icon-border-spacing {
    padding: 7px 18px;
}

.bi-button-general {
    @include borderShadowDarkAllCorners();
    outline: none !important;
}

.bi-button-overlay-general {
    align-self: flex-start; // ignore sibling's margins/paddings
    outline: none;
    cursor: pointer;
}

.bi-button-big-with-soft-rounded-corners {
    @extend .bi-button-general;
    border-radius: $border-radius-5px;
}

.bi-button-slim-with-rounded-corners {
    @extend .bi-button-general;
    border-radius: $border-radius-20px;
}

.bi-button-overlay-normal-corners {
    @extend .bi-button-general;
    box-shadow: none;
    border-radius: $border-radius-10px;
}

.bi-button-icon-sized-with-soft-rounded-corners {
    @extend .bi-button-general;
    box-shadow: none;
    border-radius: $border-radius-18px;
}

.bi-button-overlay-soft-rounded-corners {
    @extend .bi-button-overlay-general;
    border-radius: $border-radius-5px;

    &:hover {
        background-color: $overlay-grey;
        transition: all 0.2s ease;
    }
}

.bi-button-overlay-rounded-corners {
    @extend .bi-button-overlay-general;
    border-radius: $border-radius-20px;

    &:hover {
        background-color: $overlay-grey;
        transition: all 0.2s ease;
    }
}

.bi-button-overlay-icon-with-soft-rounded-corners {
    @extend .bi-button-overlay-general;
    border-radius: $border-radius-18px;

    &:hover {
        background-color: $overlay-grey;
        transition: all 0.2s ease;
    }
}
