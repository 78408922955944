@import '../../../../styles/_variables';

#notifications-email-list {

	.p-scrollpanel-wrapper {

		border-right: 9px solid $border-grey-dark;
	}
	&.p-scrollpanel .p-scrollpanel-bar {
		background-color: $org-primary;
		opacity: 1;
        visibility: visible !important;
        right: 9px !important;
	}
}

.send-email-btn-content {
	font-weight: bold;
	height: 21px;
}

.send-email-btn-container {
	margin-left: 5px;
	height: 35px;
}

.notification-2-col-grid-container {
	display: grid;
	grid-template-columns: repeat(2, 230px);
	column-gap: 14px;
}
