@import 'view/styles/_variables.scss';

@mixin fill-level-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    column-gap: 20px;
    row-gap: 15px;
    align-items: center;
    text-align: center;
  }

#fill-level-dialog-container{
    width: 70vw;
}

.fill-level-dialog{
    .p-multiselect.p-component, .p-multiselect-label-container, .p-multiselect-label {
        //max-width: 140px;
        width: auto !important;
        //min-width: inherit;
        margin: 0;
        padding: auto;
    } 

    .p-card{
        border-radius: 4px;
        background-color: $button-prime-grey;
        font-weight: bolder;
        font-size: large;
        .p-card-body{
            padding: 0.5em;
        }
    }

    
    .fill-level-grid-content{
        @include fill-level-grid();
        grid-template-rows: repeat(7, auto);
        grid-auto-flow: column;
    }
    
    .fill-level-grid-header{
        @include fill-level-grid();
        margin-bottom: 10px;
    }

}