.img-category {
	max-height: 32px;
	max-width: 32px;
}

.p-lg-3-fill-width {
	&.p-lg-3 {
		width: 100%;
	}
}

.p-scrollpanel-edit-column {
	.p-scrollpanel-wrapper {
		.p-scrollpanel-content {
			padding: 10px 18px 18px 10px;

			.category-btn {
				margin: 10px 20px 10px 0px;
			}
		}
	}
}

.edit-column {
	width: 80%;
}

.lock-column {
	width: 60%;
}

.lock-text {
	margin-top: 1px;
}

.edit-column-extra-checkbox-container {
	height: 45px;
}