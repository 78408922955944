@import "view/styles/_variables.scss";

.biTab {
    .p-tabview {
        a:focus {
            box-shadow: none !important;
        }

        .p-tabview-selected a {
            background-color: $org-primary !important;
            border: 1px solid $org-primary !important;
        }

        .p-tabview-panels {
            border: none;
            padding-left: 0;
            padding-right: 0;
            margin-right: 50px;
        }
    }
}