.position-absolute {
    position: absolute;
}

.position-fixed {
    position: fixed;
}

.position-relative {
    position: relative;
}

// default "position" value
.position-static {
    position: static;
}
