#tracker-unit-overview {
	.tracker-unit-grid {
		text-align: center;
		display: grid;
		grid-template-columns: 1fr 1fr;
	}

	.min-height-50px {
		min-height: 50px;
	}
}
