.p-scrollpanel-type-selector {
	.p-scrollpanel-wrapper {
		.p-scrollpanel-content {
			padding: 10px 18px 18px 5px;

			.category-btn {
				margin: 10px 20px 10px 0px;
			}
		}
	}
}
