@import 'view/styles/_variables.scss';
@import '../bi-button-themes.scss';

// TODO: Rename to org-primary at some point. Use Ctrl+Shift+H in VS Code to open the replace all menu for all project files.
.bi-button-org-orange {
	@include bi-element-org-general();

    &.highlight {
        @include bi-button-gradient-focus($color-from: $org-primary-dark-hover, $color-to: $org-primary-light);
    }
}

.bi-button-org-red {
	@include bi-element-org-general();
	@include bi-button-gradient-focus($color-from: $cancel-red-dark, $color-to: $cancel-red-light);
}

.bi-button-org-green {
	@include bi-element-org-general();
	@include bi-button-gradient-focus($color-from: $save-green-dark, $color-to: $save-green-light);
}

.bi-button-org-grey {
	@include bi-element-org-general();
	@include bi-button-gradient-focus($color-from: $cylinder-grey, $color-to: $metal-grey);
}
.bi-button-org-primary-grey {
	@include bi-element-org-general();
	background-color: $button-prime-grey
}
// TODO: Unless .bi-button-org-orange above stops using the primary colors, then merge this with it
.bi-button-org-primary {
	@include bi-element-org-general();
	@include bi-button-gradient-focus($color-from: $org-primary-dark, $color-to: $org-primary-light);
}

.bi-button-org-white {
	@include bi-element-org-general();
	background-color: white;

	&:hover {
		background-color: $metal-grey;
	}
}
.bi-button-org-dark-blue-with-grey-text {
	@include bi-element-org-general();
	color: white;
	background-color: #39526e;
}
