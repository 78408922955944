$org-primary: rgb(238, 166, 0);
$org-grey: rgb(40, 49, 60);
$org-red: rgb(170, 1, 50);
$org-blue: rgb(57, 82, 110);
$org-green: rgb(107, 181, 54);
$org-yellow: #ffff33;
$org-primary-light: #ffbf2b;
$org-primary-dark: #c98c00;
$org-primary-light-hover: #d6a023;
$org-primary-dark-hover: #a07001;

$grey-light: #a0a0a0;
$metal-grey: rgb(237, 237, 237);
$cylinder-grey: rgb(178, 178, 178);
$hc-machines-grey: rgb(136, 141, 143);
$hc-eg-ct-grey: rgb(75, 79, 80);
$navbar-accent: $org-primary;
$border-grey: rgb(220, 220, 220);
$border-grey-dark: rgba(182, 182, 182, 0.4);
$border-grey-dark-2: rgba(121, 121, 121, 0.4);
$table-row-grey: rgb(242, 242, 242);
$overlay-grey: rgb(199, 199, 199);

$progressbar-green-light: #62ff00;
$progressbar-green-dark: #76ed41;
$progressbar-orange-light: #fcd842;
$progressbar-orange-dark: #ffc400;
$progressbar-red-light: #fc5042;
$progressbar-red-dark: #fe0606;

$chart-gray-dark: #535a60;
$chart-gray-light: #aab1bd;

$cancel-red-light: #fc4b3e;
$cancel-red-dark: #ff0404;
$save-green-light: #5cec02;
$save-green-dark: #68d435;

$table-graph-button-inactive: #808080;

$font-size-small: 0.8em;
$font-size-normal: 1.2em;
$font-size-big: 1.6em;

$spacing-page-container-normal: 140px;
$spacing-page-container-small: 40px;
$spacing-page-container-smaller: 20px;

$spacing-margin-40-px: 40px;
$box-shadow-under: 0 5px 5px rgba(0, 0, 0, 0.1);

$button-active-grey: $grey-light;
$button-prime-grey: #f4f4f4;

$brand-logo-path: url('../../../assets/BRA-IN logo_no payoff.png');
$brand-logo-width: 90px;

$link-blue: #1a0dab;
