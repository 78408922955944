@import "view/styles/_variables.scss";

.margin-horizontal-responsive {
    margin-left: $spacing-page-container-normal;
    margin-right: $spacing-page-container-normal;
}

@media only screen and (max-width: 1920px) {
    .margin-horizontal-responsive {
        margin-left: $spacing-page-container-small;
        margin-right: $spacing-page-container-small;
    }
}

@media only screen and (max-width: 800px) {
    .margin-horizontal-responsive {
        margin-left: $spacing-page-container-smaller;
        margin-right: $spacing-page-container-smaller;
    }
}
