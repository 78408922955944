
.ie {
  justify-content: space-around;
  display: flex;
  height: 100vh;
  width: 100vW;
  text-align: center;
  background-image: url(./bg.svg);
  background-color: #fff;
}

.ie-container {
  align-self: center;
  text-align: center;
}

.p-card-title {
  font-size: 2em;
  font-family: 'Times New Roman', Times, serif;
}