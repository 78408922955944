@import "view/styles/_variables.scss";

.margin-10px {
    margin: 10px;
}

.margin-bottom--20px {
    margin-bottom: -20px;
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.margin-bottom-0-important {
    margin-bottom: 0 !important;
}

.margin-bottom-0-5em {
    margin-bottom: 0.5em;
}

.margin-bottom-1em {
    margin-bottom: 1em;
}

.margin-bottom-2em {
    margin-bottom: 2em;
}

.margin-bottom-3em {
    margin-bottom: 3em;
}

.margin-bottom-4em {
    margin-bottom: 4em;
}

.margin-bottom-5em {
    margin-bottom: 5em;
}

.margin-bottom-5px {
    margin-bottom: 5px;
}

.margin-bottom-10px {
    margin-bottom: 10px;
}

.margin-bottom-20px {
    margin-bottom: 20px;
}

.margin-bottom-30px {
    margin-bottom: 30px;
}

.margin-bottom-40 {
    margin-bottom: $spacing-margin-40-px;
}

.margin-bottom-50 {
    margin-bottom: 50px;
}

.margin-horizontal-5px {
    margin-left: 5px;
    margin-right: 5px;
}

.margin-horizontal-20px {
    margin-left: 20px;
    margin-right: 20px;
}

.margin-horizontal-30px {
    margin-left: 30px;
    margin-right: 30px;
}
.margin-left-5px {
    margin-left: 5px;
}

.margin-left-10px {
    margin-left: 10px;
}

.margin-left-20px {
    margin-left: 20px;
}

.margin-left-30px {
    margin-left: 30px;
}

.margin-left-40 {
    margin-left: $spacing-margin-40-px;
}

.margin-left-90px {
    margin-left: 90px;
}

.margin-left-auto {
    margin-left: auto;
}

.margin-right-5px {
    margin-right: 5px;
}

.margin-right-10px {
    margin-right: 10px;
}

.margin-right-14px {
    margin-right: 14px;
}

.margin-right-24px {
    margin-right: 24px;
}

.margin-right-40px {
    margin-right: $spacing-margin-40-px;
}

.margin-top-10px {
    margin-top: 10px;
}

.margin-top-15px {
    margin-top: 15px;
}

.margin-top-20px {
    margin-top: 20px;
}

.margin-top-30px {
    margin-top: 30px;
}

.margin-top-60px {
    margin-top: 60px;
}

.margin-top-80px {
    margin-top: 80px;
}

.margin-top-40 {
    margin-top: $spacing-margin-40-px;
}

.margin-top-auto {
    margin-top: auto;
}

.margin-vertical-20px { 
    margin-top: 20px;
    margin-bottom: 20px;
}

.margin-vertical-40 {
    @extend .margin-bottom-40;
    @extend .margin-top-40;
}

.margin-vertical-20px {
	margin-top: 20px;
	margin-bottom: 20px;
}

.margin-bottom-1 {
    margin-bottom: 1em;
}

.margin-left-0-8 {
    margin-left: 0.8em;
}

.margin-left-3 {
    margin-left: 3em;
}

.margin-left-5px {
    margin-left: 5px;
}

.margin-right-2 {
    margin-right: 2em
}

.margin-right-3 {
    margin-right: 3em
}

.margin-right-5 {
    margin-right: 5em;
}

.margin-vertical-32px {
    margin-left: 32px;
    margin-right: 32px;
}