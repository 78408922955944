

.history-table-edit-btn {
    display: inline-block;
    justify-content: center;
}


.history-table-dialog-grid {
    display: grid;
    grid-template-columns: 1fr 2fr;
    column-gap: 20px;
    row-gap: 40px;
    align-items: center;
}


