@import 'view/shared/components/cards/white-card.scss';
@import 'view/styles/_variables.scss';
@import 'view/styles/typography.scss';

$tile-width: 450px;
$tile-height: 300px;
$tile-margin: 20px;

.bi-text-dialog {
	.tile-dialog-wrapper {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: white;
	}

	.close-icon {
		height: 25px;
		cursor: pointer;
	}

	.subtitle {
		font-size: 20px;
		margin: 0px;
	}

	.title {
		margin-bottom: 0px;
	}

	.location-name {
		margin-bottom: 5px;
	}

	.content-wrapper {
		margin-top: 35px;
		margin-bottom: 35px;
	}

	.dashboard-tile {
		margin: $tile-margin;
		height: $tile-height;
	}

	.card-width {
		width: $tile-width;
	}
}