@import 'view/styles/_variables.scss';

.card-width-700-and-margin-spacing {
	&.card-width {
		width: (700px + $spacing-margin-40-px);
	}
}

.card-max-width-700-and-margin-spacing {
	max-width: (700px + $spacing-margin-40-px);
}

.card-max-width-500-and-margin-spacing {
	max-width: (500px + $spacing-margin-40-px);
}

$card-height-normal: 270px;
$card-width-big: 480px;

.card-height-normal {
	height: $card-height-normal;
}

.card-height-big {
	height: $card-height-normal * 2 + $spacing-margin-40-px;
}

.card-container-no-padding {
	padding: 0px;
	width: fit-content;
	flex-direction: column;
}

.card-width-big {
	width: $card-width-big;
}

.card-width-big-square {
	width: $card-width-big;
}

.flex-wrap-responsive {
	flex-wrap: wrap;
}

.padding-32px {
	padding: 32px;
	padding-bottom: 0px;
}

.width-half-screen-size-or-min-800 {
	min-width: 800px;
	width: 50vw;
}

.card-height-auto {
	height: auto;
}

.display-inherit {
	display: inherit;
}

.flex-container-forcing {
	display: flex;

	div {
		display: flex;
	}
}

.details-device-card {
	display: flex;
	flex-direction: column;
	align-items: center;
	align-self: auto; // reset to default
	width: inherit; // Remove the fixed width

	&.with-text {
		padding: 15px 20px 0 20px;
	}

	img,
	svg {
		margin-bottom: 5px;
	}

	img {
		height: 43px;
	}

	.btn-content {
		width: 167px;
	}
}

.details-device-card-fixed {
	width: 80px;
	height: 69px;
}

.details-weight-card {
	width: $card-width-big * 3 + $spacing-margin-40-px * 2;
}

.custom-msg-dialog-container {
	max-width: 900px;

	.custom-msg-grid-container {
		display: grid;
        grid-template-columns: 1fr 4fr;
        grid-auto-rows: auto;
        column-gap: 15px;
        row-gap: 20px;
        align-items: center;
	}
}

.button-border {
	margin-bottom: -0.5em
}