.p-paginator {
	border: 1px solid #ededed !important;
	background-color: transparent !important;
	width: fit-content;
	border-radius: 7px;

	.p-paginator-element {
		border-right: 1px solid #ededed !important;
	}

	.p-paginator-next {
		border: none !important;
	}

	.p-highlight {
		background-color: #ededed !important;
		color: #090909 !important;
		font-weight: bold;
		border: none !important;
	}
}
