@import "view/styles/_variables.scss";

.edit-notification-columns-table-container {
	.p-datatable {
		.p-datatable-tbody {
			tr {
				&:nth-child(even) {
					color: #333333;
					background-color: $table-row-grey;
				}
				&:nth-child(odd) {
					color: #333333;
					background-color: #ffffff;
				}

				&.p-highlight {
					&:nth-child(even) {
						color: #333333;
						background-color: $table-row-grey;
					}
					&:nth-child(odd) {
						color: #333333;
						background-color: #ffffff;
					}
				}
			
				.p-checkbox {
					color: white;
			
					.p-highlight {
						border-color: $org-primary-light;
						background-color: $org-primary-light;
					}
				}

				td {
					border: 1px solid $border-grey;
				}
			}
		}

		.p-datatable-thead > tr > th {
			background-color: $org-grey;
			color: white;

			padding: 0.75rem 0.375rem 0.75rem 0.375rem;
		}
	}
}


.edit-notification-columns-dialog-container {
	width: 30vw;
	min-width: 800px;
	margin: 10px;
}