@import '../../../../styles/variables';

.debtor-item-button-container {
	button {
		padding: 0.429em 1em;
	}
}

.debtor-dialog {
	max-width: 60%;
}

.debtor-item-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 70px;
	row-gap: 0.5rem;
}

.debtor-item-full-width-important {
	width: 100% !important;
}
