.img-baler {
    height: 24px;
    margin-right: 10px;
}

.img-baler-big {
    max-height: 320px;
    max-width: 320px;
    margin-right: 60px;
    margin-bottom: 20px;
}
