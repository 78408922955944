@import '../styles/_variables';

.checkbox-col {
	width: 45px;
}

.relative-shaded {
	padding-bottom: 20px;
	border-bottom: 0 none;
	box-shadow: $box-shadow-under;
	position: relative;
	z-index: 10;
}

.bi-button-units-selected {
	display: flex;
	> div {
		> button {
			background-color: $button-prime-grey;
			border-radius: 0;
			box-shadow: none;
			outline: none;
			border-radius: 10px;
			height: 39px;

			&:hover {
				background-color: $overlay-grey;
			}
			&:active {
				background-color: $button-active-grey;
			}
		}

	}
	> div {
		display: flex;
	align-items: flex-start;
	justify-content: center;
	flex-direction: column;
		margin-right: 10px;
		border-radius: 0;
		box-shadow: none;
		outline: none;
		border-radius: 10px;
		height: 39px;
	}
}
