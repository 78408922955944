@import '../bi-button-themes.scss';

// Hide radio
.radio-hide {
	position: absolute;
	visibility: hidden;
	width: 0;
	height: 0;
}

// Content styles
.radio-hide + div {
	cursor: pointer;
}

$org-orange: '.bi-radiobutton-org-orange';
$org-orange-colors-only: '.bi-radiobutton-org-orange-colors-only';
$org-primary-colors-only: '.bi-radiobutton-org-primary-colors-only';

// Checked styles
.radio-hide {
	&:checked + div {
		&#{$org-orange} {
			@include bi-button-gradient-focus($color-from: $org-primary-dark-hover, $color-to: $org-primary-light);
		}
		&#{$org-orange-colors-only} {
			@include bi-button-org-orange-colors-only-focus();
		}
		&#{$org-primary-colors-only} {
			@include bi-button-bg-color-transparent-only-focus();
		}
	}
}
