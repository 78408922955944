.bale-settings {
	.bale-setting-card-container {
		width: 100%;
		margin: 20px 0;

		.setting-image {
			height: 40px;
			margin-right: 20px;
			display: flex;
			align-self: center;
		}

		.big-button {
			display: flex;
			margin-left: auto;
		}
	}
	.btn-icon {
		height: 32px;
		margin-left: 5px;
	}
}
