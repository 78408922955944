@import 'view/styles/_variables.scss';

$icon-height: 25px;

.table-graph-icon {
	height: $icon-height;
}

.table-graph-icon-inactive-color {
	background-color: $table-graph-button-inactive;
}

$spacing: 7px;

.spacing-right-table-graph {
	margin-right: $spacing;
}

.spacing-left-table-graph {
	margin-left: $spacing;
}

.table-graph-border {
	height: $icon-height;
	border-right: 1px solid gray;
}
