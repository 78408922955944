@import "view/styles/_variables.scss";
@import "view/components/table/bi-table/bi-table.scss";

// (Almost) duplicate of .bi-table.scss for bi-table.tsx. Not sure if both tables should have the complete same styles
.report-table-header {
    background-color: $org-grey;
    color: white;

    >tr {
        >th {
            //@include bi-table-border-fixed-custom();

            &:not(.border-right):not(.border-left) {
                //@include bi-table-border-default-cell-custom();
            }

            // add important to counteract the annoying rgba(0,0,0,0) style in react-grid-bootstrap4
            background-color: $org-grey !important;
            padding: 0.75rem 0rem 0.75rem 0.15rem;
        }
    }
}

.bi-report-table-striped {
    >tbody {
        >tr {
            td {
                padding: 7px;
                height: 39px;
                vertical-align: middle;
                @include bi-table-border-fixed-custom();
                @include bi-table-border-default-cell-custom();
            }

            &:nth-child(odd) {
                >td, >th {
                    // add important to counteract the annoying rgba(0,0,0,0) style in react-grid-bootstrap4
                    background-color: $table-row-grey !important;
                }
            }

            &:nth-child(even) {
                >td, >th {
                    background-color: white !important;
                }
            }
        }
    }
}