@import 'view/styles/_variables.scss';
@import 'view/styles/_gradients.scss';

$table-page-icon-width: 120px;
$table-page-icon-height: 55px;
$table-page-icon-background-height: $table-page-icon-height * 3;
$background-position-on-hover: 100px;

@mixin bi-element-org-general {
	border: black;
	color: black;
	transition: all 0.2s ease;
}

@mixin bi-button-org-focus {
	color: white;
	transition: background 0.2s ease;

	&:hover {
		background-position: $background-position-on-hover;
	}
}

@mixin bi-button-gradient-focus(
	$direction: to top,
	$color-from: $org-primary-dark,
	$color-to: $org-primary-light,
	$background-size: 200px 300px
) {
	@include bi-button-org-focus();
	@include backgroundGradient($direction, $color-from, $color-to, $background-size);
}

// Source: https://codepen.io/sosuke/pen/Pjoqqp
// If used on an image, it will only color the non-tranparent areas!
@mixin bi-button-org-orange-colors-only-focus {
	// Change from one color (black with brightness and saturate) to orange.
	filter: brightness(0) saturate(100%) invert(82%) sepia(45%) saturate(999%) hue-rotate(341deg) brightness(104%)
		contrast(99%);
}

@mixin bi-button-org-green-colors-only-focus {
	// Change from one color (black with brightness and saturate) to $org-primary.
	filter: brightness(0) saturate(100%) invert(83%) sepia(15%) saturate(2521%) hue-rotate(57deg) brightness(71%)
		contrast(81%);
}

// If used on an image, it will only color the transparent areas.
@mixin bi-button-bg-color-transparent-only-focus($bg-color: $org-primary) {
	background-color: $bg-color;
}
