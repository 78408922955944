@import 'view/styles/_variables.scss';

.advanced-notification-setting-root {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-grow: 1;
	border: 1px solid $metal-grey;
	border-radius: 12px;
	padding: 13px;

	.p-dropdown {
		&.p-component {
			width: 240px;
			margin-right: 0px;

			.p-dropdown-label.p-inputtext {
				max-width: 100% !important;
			}
		}
	}
}

.advanced-notification-setting-value {
	width: 75px !important;
}
