@import "view/styles/_variables.scss";
@import "view/styles/_gradients.scss";

@mixin backgroundGradientWithHover($background-pos: 100px,
    $direction: to top,
    $color-from: $org-primary-dark-hover,
    $color-to: $org-primary-light,
    $active-class-name: '.current-view'

) {
    @include backgroundGradient($direction, $color-from, $color-to);

    &:not($active-class-name) &:hover {
        background-position: $background-pos;
    }
}

$button-width: 190px;
$button-height: 35px;
$button-background-height: $button-height * 3;

.p-button.p-button-rounded.reports-button {
    margin-right: 10px;
    margin-left: 10px;
    width: $button-width;
    height: $button-height;
    border: none;

    background: $metal-grey;
    transition: background 0.2s ease;

    background-size: 90px 165px;

    &.current-view {
        @include backgroundGradient();;
    }

    &:hover,
    &:active {
        @include backgroundGradient();
    }
}

.p-button-text {
    color: black;
}

.reports-date-button {
    &.p-button {
        background-color: transparent;
        border: none;

        &:enabled {
            &:hover {
                background-color: transparent;
                border-color: transparent;

                &:not(.active) .p-button-text {
                    color: $org-primary-light;
                }
            }

            &:active {
                background-color: transparent;
            }
        }
    }

    .p-button-text {
        color: lighten($org-primary-light, 15%);
        font-weight: bold;
        text-transform: uppercase;
        transition: color 0.2s ease;
    }

    &.active {
        .p-button-text {
            color: $org-primary;
        }
    }
}

$date-picker-border-radius: 20px;
$date-picker-margin-right: 50px;
$reports-container-margin: 110px;

.reports {
    align-items: center;

    .report-content-buttons {
        justify-content: space-between;
        align-self: flex-end;
    }

    .graph-table {
        //margin-top: 30px;
    }

    .date-picker {
        @include backgroundGradient();
        transition: background 0.2s ease;

        margin-right: $date-picker-margin-right;
        border-top-left-radius: $date-picker-border-radius;
        border-bottom-left-radius: $date-picker-border-radius;

        .p-inputtext {
            background: inherit;
            border-radius: $date-picker-border-radius;
            height: 100%;
            width: 270px;
            border: none;
            text-align: center;
            cursor: pointer;
            width: auto;
        }

        .p-button {
            background: inherit;
            border-top-right-radius: $date-picker-border-radius;
            border-bottom-right-radius: $date-picker-border-radius;
            border: none;
        }

        .p-highlight {
            background-color: $org-primary-light;
        }
    }

    .date-picker-label {
        margin-right: $date-picker-margin-right;
        font-size: 0.8em;
        font-weight: bold;
        color: $overlay-grey;
    }
}

.export-icon {
    height: 25px;
}

.min-graph-card-sizes {
    min-width: 200px;
    min-height: 150px;
}

#reports-filter-container {
    padding-top: 20px;
    margin-bottom: 0;
}
