.align-self-flex-end {
	align-self: flex-end;
}

.align-self-center {
	align-self: center;
}

.align-self-flex-start {
	align-self: flex-start;
}

.flex-container {
	display: flex;
}

.flex-center-content {
	@extend .flex-center-column;
	@extend .flex-center-row;
}

.flex-center-column {
	@extend .flex-container;
	align-items: center;
}

.flex-baseline-column {
	@extend .flex-container;
	align-items: baseline;
}

.flex-center-row {
	@extend .flex-container;
	justify-content: center;
}

.flex-direction-column {
	@extend .flex-container;
	flex-direction: column;
}

.flex-direction-row {
	@extend .flex-container;
	flex-direction: row;
}

.flex-end-column {
	@extend .flex-container;
	align-items: flex-end;
}

.flex-end-row {
	@extend .flex-container;
	justify-content: flex-end;
}

.flex-stretch-column {
	@extend .flex-container;
	align-items: stretch;
}

.flex-fill-remaining-space {
	@extend .flex-container;
	flex: 1 1 auto; // Note the values for the first and third params, 'flex-grow' and 'flex-basis'
	width: 100%;
}

.flex-fill-remaining-space-column-first-child > div {
	@extend .flex-fill-remaining-space;
	@extend .flex-direction-column;
}

.flex-fill-width {
	@extend .flex-container;
	flex: 0 1 100%; // flex-basis is set to 100 % so its width fills the whole line, thus allowing centering
}

.flex-fill-half-width {
	@extend .flex-container;
	flex: 1 1 50%; // flex-basis is set to 50 %
}

.flex-space-around-row {
	@extend .flex-container;
	justify-content: space-around;
}

.flex-space-between-row {
	@extend .flex-container;
	justify-content: space-between;
}

.flex-start-column {
	@extend .flex-container;
	align-items: flex-start;
}

.flex-start-row {
	@extend .flex-container;
	justify-content: flex-start;
}

.flex-wrap {
	@extend .flex-container;
	flex-wrap: wrap;
}

.justify-content-start {
	justify-content: flex-start;
}

.flex-1 {
	flex: 1;
}

.flex-2 {
	flex: 2;
}

.flex-3 {
	flex: 3;
}

.flex-basis-0 {
	flex-basis: 0;
}

.flex-column-gap-16 {
	column-gap: 16px;
}
