@import '../../../styles/variables';

.bi-pick-list {
	margin-left: auto;
	margin-right: auto;

	&.p-picklist {
		.p-picklist-buttons .p-button,
		.p-picklist-buttons .p-button:enabled:hover,
		.p-picklist-item.p-highlight,
		.p-picklist-item:focus {
			background-color: $org-primary !important;
			box-shadow: none !important;
			border-color: $org-primary;
		}
	}

	.p-picklist-listwrapper,
	.p-picklist-list {
		width: 100%;
		min-width: 300px;
		display: flex;
		flex-direction: column;

		.p-picklist-item div {
			display: flex;
			flex-direction: row;
			flex: 1;
		}
	}
}
