@import 'view/styles/_variables.scss';


.input-text-wrapper {
	position: relative;

	span {
		&.absolute-icon {
			position: absolute;
			right: 5px;
			top: 25%;
			color: rgb(132, 132, 132);
		}

		&.margin-right {
			right: 15px;
		}
	}
}

@mixin biInputTextDefault {
	color: #39526e !important;
	font-weight: bold !important;
	height: 33px;
	width: 140px;
	background-color: #ededed;
	border: none;
	border-radius: 0px !important;

	&::placeholder {
		color: $org-blue;
	}

	&:not(.p-disabled).p-focus {
		box-shadow: none;
	}
}

.bi-inputtext {
	&.p-component {
		@include biInputTextDefault;
		margin-right: 10px;
	}
}

.bi-inputtext-no-margin {
	&.p-component {
		@include biInputTextDefault;
	}
}
