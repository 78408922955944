@import "view/styles/_variables.scss";

// All divs are by default transparent. This is to override it.
.bg-color-white {
    background-color: white;
}

.cursor-pointer {
    cursor: pointer;
}

.display-inline-block {
    display: inline-block;
}

.display-inline-grid {
    display: inline-grid;
}


.display-none {
    display: none;
}

.no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome and Opera */
}

.justify-self-center {
    justify-self: center;
}