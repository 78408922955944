@import 'view/styles/_variables.scss';
@import 'view/components/bi-multiselect/bi-multiselect.scss';


.height-800px {
	height: 800px;
}

.p-scrollpanel-content {
	
	.p-multiselect {
		max-width: 800px !important;
		display: inline-flex;
		margin-right: 0px !important;
	}

	h6 {
		margin-bottom: 0px !important;
	}

	.p-lg-3 {
		padding: 1rem 0rem !important;
	}
}

.header-container {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	justify-items: start;
	justify-content: space-between;
	align-items: center;
	grid-template-rows: auto 1fr auto;
	padding: 10px 20px !important;

	p {
		margin: 0px !important;
		padding: 0px !important;
	}
}

.selected-machines-container {
	@extend .header-container;
	box-shadow: 2px 2px 3px 0 rgba(182, 182, 182, 0.4), -2px 2px 3px 0 rgba(182, 182, 182, 0.4);
	padding: 7px 20px !important;
	border-radius: 20px;
	margin-bottom: 10px;

	.pi.pi-arrow-right {
		justify-self: center;
	}
}

.header-details {
	font-weight: bold;
}

.selected-machine .header-details {
	justify-self: start;
}
