.editableValue {
	justify-content: space-between;

	&.hidden {
		display: none;
	}
}

.height-20 {
	height: 20px;
}

div {
	&.editableValue {
		div {
			&.p-dropdown {
				max-height: 24px;

				label {
					background-color: white !important;
					max-height: 22px;
					padding: 0;
					padding-right: 2em;
					font-size: 1rem;
					font-weight: 400 !important;
					line-height: 1.5;
				}
				.p-dropdown-trigger {
					background-color: white !important;
				}
			}
		}
	}
}

.valueForLabel {
	width: 185px;
	overflow-wrap: break-word;
	word-wrap: break-word;
	hyphens: auto;
}
