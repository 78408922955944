$margin-correction-class: '.margin-corrections-for-centering';

$size-default: 100px;
$size-half-default: $size-default / 2;
$size-1-4th-default: $size-default / 4;

.spinner-container-base {
    left: 50%;
}

.spinner-container-default-size {
    &#{$margin-correction-class} {
        margin-left: -$size-default/2;
        margin-top: -$size-default/2;
    }

    &>div {
        height: $size-default;
        width: $size-default;
    }
}

.spinner-container-half-size {
    &#{$margin-correction-class} {
        margin-left: -$size-half-default/2;
        margin-top: -$size-half-default/2;
    }

    &>div {
        height: $size-half-default;
        width: $size-half-default;
    }
}

.spinner-container-1-4th-size {
    &#{$margin-correction-class} {
        margin-left: -$size-1-4th-default/2;
        margin-top: -$size-1-4th-default/2;
    }

    &>div {
        height: $size-1-4th-default;
        width: $size-1-4th-default;
    }
}

.spinner-container-absolute {
    @extend .spinner-container-base;
    position: absolute;
}

.spinner-container-fixed {
    @extend .spinner-container-base;
    position: fixed;
    top: 50%;
}

.spinner-container-flex {
    display: flex;
    justify-content: center;
    flex: 1;
}