
.notification-utility {
    display: flex;
    flex-direction: row;
    column-gap: 15px;
    div {
        button {
            height: 39px;
        }
    }
}