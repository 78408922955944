@import "view/styles/_variables.scss";
@import "view/styles/shadows.scss";

$card-padding: 32px;

.border-shadow-dark-vertical {
    box-shadow: 0 1px 8px 0 $border-grey-dark;
}

.card-container {
    @include borderShadowDarkAllCorners();
    border-radius: 5px;
}

.label-values-card-container {
    padding: 0 $card-padding;
    width: 100%;
}

.card-container-padded {
    @extend .card-container;
    padding: $card-padding;
}

.card-width {
    width: 350px
}

.subtitle-title-padded {
    padding-top: 32px;
    padding-left: 32px;
    padding-right: 32px;
}