@import "view/styles/_variables.scss";

.p-inputtext-background-color-metal-grey .p-inputtext {
    background-color: $metal-grey;
    border: none;
}

.p-button-background-color-metal-grey {
    &.p-button {
        background-color: $metal-grey;
        border: $metal-grey;
        color: black;

        &:enabled:hover {
            background-color: $cylinder-grey;
            border: $cylinder-grey;
            color: black;
        }
    }
}

.margin-left-40px {
    margin-left: 40px;
}

.margin-right-2 {
    margin-right: 2em;
}
