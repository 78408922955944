@import 'view/styles/_variables.scss';

.releasenote-wrapper {
	h5 {
		margin-bottom: 0;
	}
	.release-note-date {
		color: $cylinder-grey;
		margin-left: auto;
	}
	text-transform: none;

	.note-header {
		margin-bottom: 8px;
	}
}
