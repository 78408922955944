.two-number-tile {
	table {
		table-layout: fixed;
		width: 100%;
	}
	td {
		width: 50%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}
