.failed-auth-card-container {
	display: flex;
	justify-content: center;
	margin: 8vh 25px;
	p {
		white-space: pre-line;
		margin: 0px;
		span {
			font-weight: bold;
		}
	}

	.card-width {
		width: unset !important;
	}
	.language-picker {
		padding: 0px !important;
	}
	.p-dropdown {
		margin-right: 0px !important;
	}
}
