@import 'view/styles/_variables.scss';


#filter-bar {
	.pi-sort {
		font-size: 30px;
	}

	// Forces all input boxes in the filter bar to hide placeholder text when active.
	input:focus::placeholder {
		color: transparent !important;
	}
}

div {
	&.flex-direction-row {
		&.filter-bar-flex-wrap>* {
			margin-bottom: 10px;
		}

		&.show-extended-filter-disabled {
			height: 43px;
		}

		&.filter-button-container {
			max-height: 33px;
			margin-bottom: 0;
			align-items: flex-start;
		}

		span.p-autocomplete {
			margin-right: 10px;

			::placeholder {
				color: $org-blue;
			}

			&:not(.p-disabled).p-focus {
				box-shadow: none;
			}

			&>input {
				color: #39526e !important;
				font-weight: bold !important;
				height: 33px;
				width: 140px;
				background-color: #ededed;
				border: none;
				border-radius: 0px !important;
			}
		}
	}
}

.filter-icon-size{
	width: 22px;
	margin-top: 4.5px;
}



.hyphen-divider {
	font-weight: bold;
	margin: 0px 3px;
}

.p-dropdown {
	&.p-component {
		height: 33px;
		display: flex;
		width: 140px;
		border: none;
		margin-right: 10px;
		outline: none;
		min-width: unset;
		background-color: white;

		&:not(.p-disabled).p-focus {
			box-shadow: none;
		}

		&.p-dropdown-clearable {
			.p-dropdown-clear-icon {
				color: white;
			}

			.p-dropdown-label {
				padding-right: 50px;
			}
		}

		&.yellow {
			label {
				&.p-dropdown-label {
					&.p-inputtext {
						background-color: $navbar-accent !important;
						color: white !important;
					}
				}
			}

			.p-dropdown-trigger {
				background-color: $org-primary;
				color: #ffffff;
			}
		}

		&.not-empty {
			.p-dropdown-label.p-inputtext {
				background-color: $org-primary;
				color: #ffffff !important;
			}

			.p-dropdown-trigger {
				background-color: $org-primary;
				color: #ffffff;
			}
		}

		.p-dropdown-label.p-inputtext {
			background-color: $metal-grey;
			color: $org-blue !important;
			max-width: 140px;
			height: 33px;
			border: none;
			border-radius: 0;
			margin-bottom: 0rem;
			font-weight: bold;
		}

		.p-dropdown-trigger {
			background-color: $metal-grey;
		}
	}
}