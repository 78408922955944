@import 'view/styles/_variables.scss';


.input-text-wrapper {
	position: relative;

	span {
		&.absolute-icon {
			position: absolute;
			right: 5px;
			top: 25%;
			color: rgb(132, 132, 132);
		}

		&.margin-right {
			right: 15px;
		}
	}
}

@mixin biInputTextDefault {
	color: black !important;
	height: 33px;
	width: 50%;
	background-color: white;
    border: none;
    border-bottom: 1px solid black;
    border-radius: 0px !important;
    padding: 0px;

	&::placeholder {
		color: $org-blue;
	}

	&:not(.p-disabled).p-focus {
		box-shadow: none;
	}
}

.bi-inputtext {
	&.p-component {
		@include biInputTextDefault;
		margin-right: 10px;
	}
}

.bi-simple-inputtext-no-margin {
	&.p-component {
		@include biInputTextDefault;
	}
}
