@import "view/styles/_variables.scss";

.side-bar {
    z-index: -1;
}

.z1 {
    z-index: 1 !important;
}

#side-bar-overlay {
    background-color: transparent !important;
}

$padding-horizontal: 30px;
$padding-vertical: 20px;

.side-bar-content {
    padding-top: $padding-vertical;
    padding-bottom: $padding-vertical;
    padding-left: $padding-horizontal;
    padding-right: $padding-horizontal;
    
    width: 535px;
}

.label-bold {
    font-weight: 500;
}

.map-wrapper {
    position: relative;
}

.tile-dialog-wrapper {
    position: absolute;
    z-index: 9999;
}

.btn-wrapper {
    position: absolute;
    right: 10px;
    top: 20px;
    z-index: 1;
    padding: 0.5rem 1rem;
}

#gmap-fill-page-id {
    width: 100%;
    z-index: 3;
    height: calc(100vh - 192px);
}

.close-btn {
    margin-top: -15px;
    margin-left: -25px;
}

.sidebar-header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.pt-25 {
    padding-top: 25px;
}

.sidebar-header {
    margin-bottom: 0;
}

.online-status-wrapper {
    display: inline-flex;
    align-items: center;
}

.online-status-text {
    padding-right: 15px;
}

.width-230-px {
    min-width: 230px; //Fixed witdh on the LabelValue card's labels (overwriting here because I don't see any error elsewhere)
}

.online-status-icon {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 5px;

    &.Online {
        background: linear-gradient(to right, $progressbar-green-light, $progressbar-green-dark);
    }

    &.Offline {
        background: #585858
    }

    &.Failure {
        background: linear-gradient(to right, $progressbar-red-light, $progressbar-red-dark);
    }

    &.Warning {
        background: linear-gradient(to right, $progressbar-orange-light, $progressbar-orange-dark);
    }

    &.No {
        &.modem {
            background-color: lightgrey;
            background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMCA3LjI0NGMzLjA3MS0zLjI0IDcuMzE0LTUuMjQ0IDEyLTUuMjQ0IDQuNjg3IDAgOC45MjkgMi4wMDQgMTIgNS4yNDRsLTIuMDM5IDIuMTVjLTIuNTQ5LTIuNjg4LTYuMDcxLTQuMzUyLTkuOTYxLTQuMzUycy03LjQxMiAxLjY2NC05Ljk2MSA0LjM1MmwtMi4wMzktMi4xNXptNS43MiA2LjAzNGMxLjYwNy0xLjY5NiAzLjgyNy0yLjc0NCA2LjI4LTIuNzQ0czQuNjczIDEuMDQ4IDYuMjggMi43NDRsMi4wOTMtMi4yMDhjLTIuMTQzLTIuMjYxLTUuMTAzLTMuNjU5LTguMzczLTMuNjU5cy02LjIzIDEuMzk4LTguMzczIDMuNjU5bDIuMDkzIDIuMjA4em0zLjY1OCAzLjg1OWMuNjcxLS43MDggMS41OTgtMS4xNDUgMi42MjItMS4xNDUgMS4wMjMgMCAxLjk1MS40MzcgMi42MjIgMS4xNDVsMi4wNTctMi4xN2MtMS4xOTctMS4yNjMtMi44NTEtMi4wNDQtNC42NzgtMi4wNDRzLTMuNDgxLjc4Mi00LjY3OCAyLjA0NGwyLjA1NSAyLjE3em0yLjYyMiAxLjAxN2MtMS4wNjIgMC0xLjkyMy44NjEtMS45MjMgMS45MjNzLjg2MSAxLjkyMyAxLjkyMyAxLjkyMyAxLjkyMy0uODYxIDEuOTIzLTEuOTIzLS44NjEtMS45MjMtMS45MjMtMS45MjN6Ii8+PC9zdmc+'),
                linear-gradient(to top right,
                    rgba(0, 0, 0, 0) 0%,
                    rgba(0, 0, 0, 0) calc(50% - 0.8px),
                    rgba(0, 0, 0, 1) 50%,
                    rgba(0, 0, 0, 0) calc(50% + 0.8px),
                    rgba(0, 0, 0, 0) 100%);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 75%;
        }
    }
}

.dashboard-tile .card-container {
    height: 100%;
}