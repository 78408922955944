$dialog-width: 800px;

.dialog-width {
	min-width: $dialog-width;
	max-width: 100vw;
}

.dialog-padding {
	&.p-dialog {
		.p-dialog-content {
			padding: 40px;
		}
	}
}

@media only screen and (max-width: ($dialog-width + 100px)) {
	.dialog-width {
		min-width: 600px;
	}
}

.scrollpanel-container {
	width: 100%;
}

.scrollpanel-container-normal {
	@extend .scrollpanel-container;
	max-height: 50vh;
}

.scrollpanel-container-big {
	@extend .scrollpanel-container;
	max-height: 75vh;
}
