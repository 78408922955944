@import "view/styles/_variables.scss";

$margin-10: 10px;
$table-page-icon-width: 90px;
$table-page-icon-height: 55px;
$table-page-icon-background-height: $table-page-icon-height * 3;

// Used when the element with this class is pressed.
.current-table-page {
    display: inline-block;

    .table-page-icon-box {
        background: linear-gradient(to top, $org-primary-dark-hover, $org-primary-light);
        transition: background 0.2s ease;

        background-size: $table-page-icon-width $table-page-icon-background-height;
    }
}

.table-page-icon {
    height: 35px;
}

.table-page-icon-box {
    width: $table-page-icon-width;
    height: $table-page-icon-height;
    margin: 0 $margin-10 0 0;
    border-radius: 10px;
    background-color: $metal-grey;
}
