@import "view/styles/_variables.scss";

#release-note-admin {
	.release-note-grid {
		text-align: center;
		display: grid;
        grid-template-columns: 1fr 3fr 1fr;
        column-gap: 10px;
    }
    
    .min-height-50px{
        min-height: 50px;
    }
}

.grey-text {
    color: $cylinder-grey;
}

.release-note-dialog {
    width: 60%;
}