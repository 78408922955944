@import '../../../../styles/variables';

.decommission-item-button-container {
	button {
		padding: 0.429em 1em;
	}
}

.decommission-dialog {
	max-width: 60%;
}

.decommission-item-full-width-important {
	width: 100% !important;
}
