@import "view/styles/_variables.scss";
@import "view/styles/_variables-common.scss";

.color-green {
    color: $org-green;
}

.color-green-light {
    color: $progressbar-green-light;
}

.color-green-dark {
    color: $progressbar-green-dark;
}

.color-orange-light {
    color: $warning-orange;
}

.color-red {
    color: $org-red;
}

.color-red-dark {
    color: $progressbar-red-dark;
}

.color-info-blue {
    color: $info-blue;
}

.color-error-red {
    color: $error-red;
}

.color-warning-orange {
    color: $warning-orange;
}

.color-black {
    color: $default-body-black;
}