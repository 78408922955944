@import 'view/styles/_variables.scss';

#user-manager-dropdowns {
	.p-dialog {
		width: 50vw;
		min-width: 300px;
		max-width: 550px;
	}

	.p-multiselect-label-container {
		label {
			width: 200px !important;
		}
	}

	.tree-select-dropdown #org-background.p-multiselect-label-container {
		min-width: 0px !important;
		width: unset !important;
	}

	.content-container-large {
		.p-component {
			width: 100% !important;
			margin-right: 0px !important;
			min-width: 0px !important;

			&.bi-checkbox {
				width: 20px !important;
			}
		}

		p {
			margin: 6px 0px !important;
		}
	}

	button {
		.p-button-text {
			color: white;
		}
	}
}
